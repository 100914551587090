"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = collections;
function collections() {
  var _this = this;

  return {
    listCollections: function listCollections() {
      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;

      var url = "/collections";

      var query = {
        page: page,
        per_page: perPage
      };

      return _this.request({
        url: url,
        method: "GET",
        query: query
      });
    },

    getCollection: collection.bind(this),

    getCollectionPhotos: collectionPhotos.bind(this),

    createCollection: createUpdateCollection.bind(this, null),

    updateCollection: createUpdateCollection.bind(this),

    deleteCollection: function deleteCollection(id) {
      var url = "/collections/" + id;

      return _this.request({
        url: url,
        method: "DELETE"
      });
    },

    addPhotoToCollection: function addPhotoToCollection(collectionId, photoId) {
      var url = "/collections/" + collectionId + "/add";

      return _this.request({
        url: url,
        method: "POST",
        body: {
          photo_id: photoId
        }
      });
    },

    removePhotoFromCollection: function removePhotoFromCollection(collectionId, photoId) {
      var url = "/collections/" + collectionId + "/remove?photo_id=" + photoId;

      return _this.request({
        url: url,
        method: "DELETE"
      });
    },

    listRelatedCollections: function listRelatedCollections(collectionId) {
      var url = "/collections/" + collectionId + "/related";

      return _this.request({
        url: url,
        method: "GET"
      });
    }
  };
}

function collection(id) {
  return this.request({
    url: "/collections/" + id,
    method: "GET"
  });
}

function collectionPhotos(id) {
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var orderBy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "latest";

  var query = {
    page: page,
    per_page: perPage,
    order_by: orderBy
  };

  return this.request({
    url: "/collections/" + id + "/photos",
    method: "GET",
    query: query
  });
}

function createUpdateCollection(id, title, description, isPrivate) {
  var url = id ? "/collections/" + id : "/collections";
  var body = {
    title: title,
    description: description,
    "private": isPrivate
  };

  return this.request({
    url: url,
    method: id ? "PUT" : "POST",
    body: body
  });
}