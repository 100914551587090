"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = search;
function search() {
  var _this = this;

  return {
    photos: function photos() {
      var keyword = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
      var filters = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var collections = filters.collections || [];
      var query = {
        query: encodeURIComponent(keyword),
        per_page: perPage,
        orientation: filters.orientation,
        collections: collections.join(),
        page: page
      };

      Object.keys(query).forEach(function (key) {
        if (!query[key] && key != "query") {
          delete query[key];
        }
      });

      return _this.request({
        url: "/search/photos",
        method: "GET",
        query: query
      });
    },

    users: function users() {
      var keyword = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;

      var query = {
        query: encodeURIComponent(keyword),
        per_page: perPage,
        page: page
      };

      return _this.request({
        url: "/search/users",
        method: "GET",
        query: query
      });
    },

    collections: function collections() {
      var keyword = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;

      var query = {
        query: encodeURIComponent(keyword),
        per_page: perPage,
        page: page
      };

      return _this.request({
        url: "/search/collections",
        method: "GET",
        query: query
      });
    }
  };
}